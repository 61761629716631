import Vue from 'vue'

import {library} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faBars,
    faBuilding,
    faCheckCircle,
    faCopy,
    faMobile,
    faPen,
    faPlusCircle,
    faRedoAlt,
    faSearch,
    faServer,
    faSignOutAlt,
    faTags,
    faTimes,
    faTrash,
    faUnlock,
    faUser,
    faUserTag,
    faBell,
    faCheck,
    faArchive,
} from "@fortawesome/free-solid-svg-icons";

library.add(faCheckCircle, faPlusCircle, faPen, faTrash, faTimes, faSearch, faCopy, faRedoAlt, faBuilding, faUser, faUserTag, faTags, faUnlock, faMobile, faServer, faBars, faSignOutAlt, faBell, faCheck, faArchive)

Vue.component('font-awesome-icon', FontAwesomeIcon)
