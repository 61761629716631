import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {ClientAppsApi} from 'stalactite-auth-client'
import providers from './providers';
import axios from 'axios'

const API = new ClientAppsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
        total: 0,
        pageSize: 0,
        clientApp: null
    },
    modules: {
        providers
    },
    mutations: {
        'SET_CLIENT_APPS': (state, {_metas, results}) => {
            state.total = _metas.total
            state.pageSize = _metas.pageSize
            state.all = results
        },
        'SET_CLIENT_APP': (state, clientApp) => {
            state.clientApp = clientApp
        },
        'ADD_CLIENT_APP': (state, clientApp) => {
            state.all.push(clientApp)
        },
        'UPDATE_CLIENT_APP': (state, clientApp) => {
            const index = state.all.findIndex(item => item.uid === clientApp.uid)
            if (index >= 0) {
                state.all[index] = clientApp
            }
            state.clientApp = clientApp
        },
        'DELETE_CLIENT_APP': (state, clientApp) => {
            state.all = state.all.filter(item => item.uid !== clientApp.uid)
        }
    },
    actions: {
        async fetchClientApps({commit}, params) {
            let resp;
            try {
                resp = await API.getClientApps(params.page, params.name ?? undefined)
            } catch (err) {
                commit('SET_CLIENT_APPS', [])
                throw ErrorHandler.handle(err)
            }
            commit('SET_CLIENT_APPS', resp.data)
        },
        async fetchClientApp({commit}, uid) {
            let resp;
            try {
                resp = await API.getClientApp(uid)
            } catch (err) {
                commit('SET_CLIENT_APP', null)
                throw ErrorHandler.handle(err)
            }
            commit('SET_CLIENT_APP', resp.data)
            await this.dispatch("clientApps/providers/fetchProviders", {uid})
        },
        async addClientApp({commit}, ca) {
            let resp;
            try {
                resp = await API.createClientApp({
                    name: ca.name.trim()
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('ADD_CLIENT_APP', resp.data)
        },
        async updateClientApp({commit}, ca) {
            try {
                await API.updateClientApp(ca.uid, {
                    name: ca.name.trim()
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('UPDATE_CLIENT_APP', ca)
        },
        async deleteClientApp({commit}, uid) {
            try {
                await API.deleteClientApp(uid)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_CLIENT_APP', {uid: uid})
        }
    }
}