import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {PostsApi} from 'stalactite-data-client'

import axios from 'axios'

const API = new PostsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
        post: null
    },
    mutations: {
        'SET_POSTS': (state, posts) => {
            state.all = posts
        },
        'SET_POST': (state, post) => {
            state.post = post
        },
        'ADD_POST': (state, post) => {
            state.all.push(post)
        },
        'UPDATE_POST': (state, post) => {
            const index = state.all.findIndex(item => item.uid === post.uid)
            if (index >= 0) {
                state.all[index] = post
            }
            state.post = post
        },
        'DELETE_POST': (state, post) => {
            state.all = state.all.filter(item => item.uid !== post.uid)
        },
        'ADD_PERMISSIONS': (state, permissions) => {
            state.post.permissions.push(...permissions)
        },
        'REMOVE_PERMISSIONS': (state, permissions) => {
            state.post.permissions = state.post.permissions?.filter(
                (pPermission) => !permissions.some(permission => pPermission.uid === permission.uid)
            );
        },
    },
    actions: {
        async fetchPosts({commit}) {
            let resp;
            try {
                resp = await API.getPosts()
            } catch (err) {
                commit('SET_POSTS', [])
                throw ErrorHandler.handle(err)
            }
            commit('SET_POSTS', resp.data)
        },
        async fetchPost({commit}, uid) {
            let resp;
            try {
                resp = await API.getPost(uid)
            } catch (err) {
                commit('SET_POST', null)
                throw ErrorHandler.handle(err)
            }
            commit('SET_POST', resp.data)
        },
        async addPost({commit}, post) {
            let resp;
            try {
                resp = await API.createPost({
                    name: post.name.trim(),
                    shortName: post.shortName.trim()
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('ADD_POST', resp.data)
        },
        async updatePost({commit}, post) {
            try {
                await API.updatePost(post.uid, {
                    name: post.name.trim(),
                    shortName: post.shortName.trim(),
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('UPDATE_POST', post)
        },
        async deletePost({commit}, uid) {
            try {
                await API.deletePost(uid)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_POST', {uid: uid})
        },
        async addPermissions({commit}, {post, permissions}) {
            if (permissions.length === 0) {
                return
            }

            try {
                await API.addPostPermissions(post.uid, {permissions: permissions.map(permission => permission.uid)})
            } catch (err) {
                throw ErrorHandler.handle(err) // todo : specify error message
            }

            commit('ADD_PERMISSIONS', permissions)
        },
        async removePermissions({commit}, {post, permissions}) {
            if (permissions.length === 0) {
                return
            }

            try {
                await API.deletePostPermissions(post.uid, {permissions: permissions.map(permission => permission.uid)})
            } catch (err) {
                throw ErrorHandler.handle(err) // todo : specify error message
            }

            commit('REMOVE_PERMISSIONS', permissions)
        }
    }
}
