export default [
    {
        path: '/serverApps',
        name: 'serverApps',
        component: () => import(/* webpackChunkName: "ServerApps" */ '@/views/serverApps/ServerApps'),
        meta: {
            label: "Applications serveurs"
        }
    },
    {
        path: '/serverApps/add',
        name: 'addServerApp',
        component: () => import(/* webpackChunkName: "AddServerApp" */ '@/views/serverApps/AddServerApp'),
        meta: {
            label: "Ajouter une application serveur"
        }
    },
    {
        path: '/serverApps/:uid',
        name: 'serverApp',
        props: true,
        component: () => import(/* webpackChunkName: "ServerApp" */ '@/views/serverApps/ServerApp'),
        meta: {
            label: "Modifier une application serveur"
        }
    }
]