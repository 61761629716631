export default [
    {
        path: '/domains',
        name: 'domains',
        component: () => import(/* webpackChunkName: "Domaines" */ '@/views/domains/Domains'),
        meta: {
            label: "Domaines"
        }
    },
    {
        path: '/domains/add',
        name: 'addDomain',
        component: () => import(/* webpackChunkName: "AddDomain" */ '@/views/domains/AddDomain'),
        meta: {
            label: "Ajouter un domaine"
        }
    },
    {
        path: '/domains/:uid',
        name: 'domain',
        props: true,
        component: () => import(/* webpackChunkName: "Domaine" */ '@/views/domains/Domain'),
        meta: {
            label: "Modifier un domaine"
        }
    }
]