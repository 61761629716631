<template>
    <span class="jalis" :style="{'font-size': size}">
        <span>Jalis</span> Connect
    </span>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: "200%"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

span.jalis {
  font-family: 'Lobster', cursive;
  color: $jalis-grey;

  & :first-child {
    color: $jalis-blue;
  }
}
</style>