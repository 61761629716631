import Vue from 'vue'
// eslint-disable-next-line no-unused-vars
import {default as VueRouter, RawLocation} from 'vue-router'

import users from './users'
import clientApps from './clientApps'
import providers from "./providers";
import serverApps from './serverApps'
import domains from './domains'
import customers from './customers'
import posts from './posts'

import AuthMiddleware from './middlewares/auth'
import permissions from './permissions'
import tickets from "./tickets";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/Home'),
        meta: {
            anonymous: true,
        }
    },
    {
        path: "/notFound",
        alias: "*",
        name: "notFound",
        component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound')
    },
    {
        path: "/connection",
        name: "jalisConnectAuth",
        component: () => import('@/views/JalisConnect'),
        meta: {
            anonymous: true,
        }
    },
    ...users,
    ...clientApps,
    ...providers,
    ...serverApps,
    ...domains,
    ...customers,
    ...posts,
    ...permissions,
    ...tickets,
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

/**
 * @param location
 * @returns {
 * Promise<{
 *      redirected: boolean
 *      route: RawLocation
 * }>
 * }
 */
VueRouter.prototype.pushIfNecessary = async (location) => {
    if (router.resolve(location).route.name === router.currentRoute.name) {
        return {
            redirected: false,
            route: await Promise.resolve(location.route)
        };
    }
    return {
        redirected: true,
        route: await router.push(location)
    };
}

router.beforeEach(AuthMiddleware)

export default router
