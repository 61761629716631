import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {UsersLeadsApi} from 'stalactite-data-client'

import axios from 'axios'

const API = new UsersLeadsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: []
    },
    mutations: {
        'SET_LEADS': (state, leads) => {
            state.all = leads
        },
        'ADD_LEADS': (state, leads) => {
            state.all = [...state.all, ...leads]
        },
        'REMOVE_LEADS': (state, leads) => {
            state.all = state.all.filter(userLead => !leads.some(lead => lead.uid === userLead.uid))
        }
    },
    actions: {
        async fetchLeads({commit}, {uid}) {
            let resp;
            try {
                resp = await API.getUserLeads(uid)
            } catch (err) {
                commit('SET_LEADS', [])
                throw ErrorHandler.handle(err);
            }

            commit('SET_LEADS', resp.data)
        },
        async addLeads({commit}, {user, leads}) {

            if (leads.length <= 0) {
                return;
            }

            const data = {leads: leads.map(lead => lead.uid)}

            try {
                await API.addUserLeads(user.uid, data);
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('ADD_LEADS', leads)
        },
        async removeLeads({commit}, {user, leads}) {
            if (leads.length <= 0) {
                return;
            }

            const data = {leads: leads.map(lead => lead.uid)}

            try {
                await API.removeUserLeads(user.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('REMOVE_LEADS', leads)
        }
    }
}