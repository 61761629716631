import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {PermissionsApi} from 'stalactite-data-client'

import axios from 'axios'

const API = new PermissionsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
        permission: null
    },
    mutations: {
        'SET_PERMISSIONS': (state, permissions) => {
            state.all = permissions
        },
        'SET_PERMISSION': (state, permission) => {
            state.permission = permission
        },
        'ADD_PERMISSION': (state, permission) => {
            state.all.push(permission)
        },
        'UPDATE_PERMISSION': (state, permission) => {
            const index = state.all.findIndex(item => item.uid === permission.uid)
            if (index >= 0) {
                state.all[index] = permission
            }
            state.permission = permission
        },
        'DELETE_PERMISSION': (state, permission) => {
            state.all = state.all.filter(item => item.uid !== permission.uid)
        }
    },
    actions: {
        async fetchPermissions({commit}) {
            let resp;
            try {
                resp = await API.getPermissions()
            } catch (err) {
                commit('SET_PERMISSIONS', [])
                throw ErrorHandler.handle(err)
            }
            commit('SET_PERMISSIONS', resp.data)
        },
        async fetchPermission({commit}, uid) {
            let resp;
            try {
                resp = await API.getPermission(uid)
            } catch (err) {
                commit('SET_PERMISSION', null)
                throw ErrorHandler.handle(err)
            }
            commit('SET_PERMISSION', resp.data)
        },
        async addPermission({commit}, permission) {
            let resp;
            try {
                resp = await API.createPermission({
                    scope: permission.scope.trim(),
                    resource: permission.resource.trim(),
                    operation: permission.operation.trim(),
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('ADD_PERMISSION', resp.data)
        },
        async updatePermission({commit}, permission) {
            try {
                await API.updatePermission(permission.uid, {
                    scope: permission.scope.trim(),
                    resource: permission.resource.trim(),
                    operation: permission.operation.trim(),
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('UPDATE_PERMISSION', permission)
        },
        async deletePermission({commit}, uid) {
            try {
                await API.deletePermission(uid)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_PERMISSION', {uid: uid})
        }
    }
}