import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {TicketsApi} from "stalactite-tickets-client";

import axios from 'axios'

const API = new TicketsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
    },
    mutations: {
        // set global collection
        'SET_TICKETS': (state, tickets) => {
            state.all = tickets
        },
        'ACCEPT_TICKET': (state, response) => {
            state.response = response
        },
        'ADD_TICKET': (state, ticket) => {
            state.ticket = ticket
        },
    },
    actions: {
        async fetchTickets({commit}) {
            let resp;

            try {
                resp = await API.getTickets()
            } catch (err) {
                commit('SET_TICKETS', []);
                throw ErrorHandler.handle(err)
            }
            commit('SET_TICKETS', resp.data)
        },

        async acceptTicket({commit}, uid) {

            try {
                await API.acceptTicket(uid)
            } catch (err) {
                commit('ACCEPT_TICKET', []);
                throw ErrorHandler.handle(err)
            }
        },

        async refuseTicket({commit}, uid) {

            try {
                await API.refuseTicket(uid)
            } catch (err) {
                commit('SET_TICKETS', []);
                throw ErrorHandler.handle(err)
            }
        },

        refreshList({commit},ticketUid) {
            const tickets = this.state.tickets.all.filter( (t) => t.uid !== ticketUid);
            commit('SET_TICKETS', tickets)
        },

        async createTicket({commit}, ticket) {
            let resp;
            try {
                resp = await API.createTicket({
                    domain: ticket.domain.trim(),
                    description: ticket.comment.trim()
                })
            } catch (err) {
                commit('ADD_TICKET', err);
                throw ErrorHandler.handle(err)
            }
            commit('ADD_TICKET', resp.data)
        }
    }
}
