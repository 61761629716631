export default [
    {
        path: '/clientApps',
        name: 'clientApps',
        component: () => import(/* webpackChunkName: "ClientApps" */ '@/views/clientApps/ClientApps'),
        meta: {
            label: "Applications clientes"
        }
    },
    {
        path: '/clientApps/add',
        name: 'addClientApp',
        component: () => import(/* webpackChunkName: "AddClientApp" */ '@/views/clientApps/AddClientApp'),
        meta: {
            label: "Ajouter une application cliente"
        }
    },
    {
        path: '/clientApps/:uid',
        name: 'clientApp',
        props: true,
        component: () => import(/* webpackChunkName: "ClientApp" */ '@/views/clientApps/ClientApp'),
        meta: {
            label: "Modifier une application cliente"
        }
    }
]