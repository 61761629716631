import relations from './relations'

import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {DomainsApi} from 'stalactite-data-client'
import axios from 'axios'

const API = new DomainsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
        total: 0,
        pageSize: 0,
        domain: null
    },
    mutations: {
        // set global collection
        'SET_DOMAINS': (state, {_metas, results}) => {
            state.total = _metas.total
            state.pageSize = _metas.pageSize
            state.all = results
        },
        // set current domain
        'SET_DOMAIN': (state, domain) => {
            state.domain = domain
        },
        'SET_DOMAIN_RELATIONS': (state, relations) => {
            if (typeof state.domain === "object") {
                state.domain.relations = relations
            }
        },
        // update current domain and global collection
        'UPDATE_DOMAIN': (state, domain) => {
            const index = state.all.findIndex(item => item.uid === domain.uid)
            if (index >= 0) {
                state.all[index] = domain
            }
            state.domain = domain
        },
        // add a domain to the global collection
        'ADD_DOMAIN': (state, domain) => {
            state.all.push(domain)
        },
        // remove a domain from the global collection
        'DELETE_DOMAIN': (state, domain) => {
            state.all = state.all.filter(item => item.uid !== domain.uid)
        }
    },
    actions: {
        async fetchDomains({commit}, params) {
            let resp;
            try {
                resp = await API.getDomains(params.page, params.name ?? undefined)
            } catch (err) {
                commit('SET_DOMAINS', []);
                throw ErrorHandler.handle(err)
            }
            commit('SET_DOMAINS', resp.data)
        },
        async fetchDomain({commit}, uid) {
            let resp;
            try {
                resp = await API.getDomain(uid)
            } catch (err) {
                commit('SET_DOMAIN', null);
                throw ErrorHandler.handle(err)
            }
            commit('SET_DOMAIN', resp.data)
            await this.dispatch("domains/relations/fetchRelations", {uid})
        },
        async addDomain({commit}, domain) {
            const data = {
                name: domain.name.trim(),
                type: domain.type.trim(),
                apiKey: domain.apiKey.trim(),
                generationDate: domain.generationDate.trim(),
                externalAuth: domain.externalAuth,
            }

            let resp;
            try {
                resp = await API.createDomain(data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('ADD_DOMAIN', resp.data)
        },
        async updateDomain({commit}, domain) {
            const data = {
                name: domain.name.trim(),
                type: domain.type.trim(),
                apiKey: domain.apiKey.trim(),
                generationDate: domain.generationDate.trim(),
                externalAuth: domain.externalAuth,
            }

            try {
                await API.updateDomain(domain.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('UPDATE_DOMAIN', domain)
        },
        async deleteDomain({commit}, uid) {
            try {
                await API.deleteDomain(uid)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_DOMAIN', {uid: uid})
        }
    },
    modules: {
        relations
    }
}
