<template>
  <b-navbar variant="faded" type="light" class="mb-2">
    <b-navbar-brand v-if="isUser" href="#" v-b-toggle.app-sidebar>
      <font-awesome-icon icon="bars"/>
    </b-navbar-brand>
    <AppTitle v-b-toggle.app-sidebar/>
    <span
        v-if="$route.meta.label"
        style="font-size:200%; font-style: italic; font-weight: lighter;"
        v-b-toggle.app-sidebar
    >
        &nbsp;• {{ $route.meta.label }}
      </span>
    <AppSidebar v-if="isUser"/>
    <b-navbar-nav class="ml-auto">
      <b-button v-if="isLoggedIn" @click="logout" pill variant="transparent">
        <font-awesome-icon icon="sign-out-alt" style="color: red"/>
      </b-button>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import AppTitle from "./AppTitle"
import AppSidebar from "./AppSidebar";
import {mapGetters} from "vuex"

export default {
  components: {
    AppTitle,
    AppSidebar
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isUser: 'auth/isUser',
    })
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout');
      await this.$router.pushIfNecessary({name: 'home'});
    }
  }
}
</script>

<style scoped>
.navbar {
  user-select: none;
}
</style>
