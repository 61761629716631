import ErrorHandler from '@/errors/handlers/ErrorHandler'
import {CustomersApi} from 'stalactite-data-client'

import axios from 'axios'

const API = new CustomersApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
        total: 0,
        pageSize: 0,
        customer: null
    },
    mutations: {
        'SET_CUSTOMERS': (state, {_metas, results}) => {
            state.total = _metas.total
            state.pageSize = _metas.pageSize
            state.all = results
        },
        'SET_CUSTOMER': (state, customer) => {
            state.customer = customer
        },
        'UPDATE_CUSTOMER': (state, customer) => {
            const index = state.all.findIndex(item => item.uid === customer.uid)
            if (index >= 0) {
                state.all[index] = customer
            }
            state.customer = customer
        },
        'ADD_CUSTOMER': (state, customer) => {
            state.all.push(customer)
        },
        'DELETE_CUSTOMER': (state, customer) => {
            state.all = state.all.filter(item => item.uid !== customer.uid)
        }
    },
    actions: {
        async fetchCustomers({commit}, params) {
            let resp;
            try {
                resp = await API.getCustomers(params.page, params.search ?? undefined)
            } catch (err) {
                commit('SET_CUSTOMERS', [])
                throw ErrorHandler.handle(err)
            }
            commit('SET_CUSTOMERS', resp.data)
        },
        async fetchCustomer({commit}, uid) {
            let resp;
            try {
                resp = await API.getCustomer(uid)
            } catch (err) {
                commit('SET_CUSTOMER', null)
                throw ErrorHandler.handle(err)
            }
            commit('SET_CUSTOMER', resp.data)
        },
        async addCustomer({commit}, customer) {
            const data = {
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email
            }

            let resp;
            try {
                resp = await API.createCustomer(data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('ADD_CUSTOMER', resp.data)
        },
        async updateCustomer({commit}, customer) {
            const data = {
                firstName: customer.firstName,
                lastName: customer.lastName,
                email: customer.email
            }

            try {
                await API.updateCustomer(customer.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('UPDATE_CUSTOMER', customer)
        },
        async deleteCustomer({commit}, uid) {
            try {
                await API.deleteCustomer(uid)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_CUSTOMER', {uid: uid})
        }
    }
}
