import AuthenticationError from '../AuthenticationError'
import StalactiteError from '../StalactiteError'

import AxiosErrorHandler from './AxiosErrorHandler'

export default class ErrorHandler {
    static handle(error) {
        if (error.isAxiosError) { // Axios error
            return AxiosErrorHandler.handle(error)
        } else if (error instanceof AuthenticationError || error instanceof StalactiteError) {
            return new Error(error.message)
        }
        return new Error('Une erreur est survenue')
    }
}