<template>
  <div id="app">
    <AppNavBar />
    <b-container>
      <router-view />
    </b-container>
  </div>
</template>

<script>
import AppNavBar from "@/components/nav/AppNavBar";

export default {
  components: {
    AppNavBar,
  },
};
</script>

<style lang="scss">
@import "./scss/style.scss";
</style>
