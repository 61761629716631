export default [
    {
        path: '/providers/google/:uid',
        name: 'googleProvider',
        props: true,
        component: () => import(/* webpackChunkName: "GoogleProvider" */ '@/views/providers/GoogleProvider'),
        meta: {
            label: "Modifier un fournisseur d'authentification Google"
        }
    },
    {
        path: '/providers/firebase/:uid',
        name: 'firebaseProvider',
        props: true,
        component: () => import(/* webpackChunkName: "FirebaseProvider" */ '@/views/providers/FirebaseProvider'),
        meta: {
            label: "Modifier un fournisseur d'authentification Firebase"
        }
    },
]