import {AuthProvidersApi} from "stalactite-auth-client";
import ErrorHandler from "@/errors/handlers/ErrorHandler";

import axios from 'axios'

const API = new AuthProvidersApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        type: null,
        provider: null,
    },
    mutations: {
        'SET_PROVIDER': (state, {type, provider}) => {
            state.type = type
            state.provider = provider
        }
    },
    actions: {
        async fetchProvider({commit}, {type, uid}) {
            let resp;
            try {
                switch (type) {
                    case 'google':
                        resp = await API.getGoogleAuthprovider(uid)
                        break
                    case 'firebase':
                        resp = await API.getFirebaseAuthprovider(uid)
                        break
                    default:
                        throw new Error('Invalid provider type')
                }
            } catch (err) {
                commit('SET_PROVIDER', {type: null, provider: null})
                throw ErrorHandler.handle(err)
            }
            commit('SET_PROVIDER', {type, provider: resp.data})
        },
        async updateProvider({commit}, {type, provider}) {
            try {
                switch (type) {
                    case 'google':
                        await API.updateGoogleAuthProvider(provider.uid, provider)
                        break
                    case 'firebase':
                        await API.updateFirebaseAuthProvider(provider.uid, provider)
                        break
                    default:
                        throw new Error('Invalid provider type')
                }
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('SET_PROVIDER', {type, provider})
        },
    }
}