import StalactiteErrorHandler from './StalactiteErrorHandler'

export default class AxiosErrorHandler {
    static handle({request, response}) {
        if (response !== undefined) { // => error from the response

            if (this.isStalactiteRequest(request)) {
                const stalactiteError = StalactiteErrorHandler.handle(request, response)
                if (stalactiteError instanceof Error) {
                    return stalactiteError
                }
            }

            // return new Error("MAIS T'AVAIS DIT QU'ON MANGÉ DES KNACKIS !!!")
            return new Error(this.getHttpCodeMessage(response.status))
        }
        return new Error('Une erreur est survenue lors de la requête')
    }

    static isStalactiteRequest(request) {
        return !!request?.responseURL?.startsWith(process.env.VUE_APP_STALACTITE_API_HOST)
    }

    static getHttpCodeMessage(code) {
        switch (code) {
            case 401:
                return 'Vous devez vous authentifier'
            case 403:
                return 'Vous n\'avez pas la permission nécessaire pour réaliser cette requête'
            case 404:
                return 'La resource demandée n\'existe pas'
            default:
                return 'Une erreur est survenue'
        }
    }
}