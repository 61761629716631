export default [
    {
        path: '/customers',
        name: 'customers',
        component: () => import(/* webpackChunkName: "Customers" */ '@/views/customers/Customers'),
        meta: {
            label: "Clients"
        }
    },
    {
        path: '/customers/add',
        name: 'addCustomer',
        component: () => import(/* webpackChunkName: "AddCustomer" */ '@/views/customers/AddCustomer'),
        meta: {
            label: "Ajouter un client"
        }
    },
    {
        path: '/customers/:uid',
        name: 'customer',
        props: true,
        component: () => import(/* webpackChunkName: "Customer" */ '@/views/customers/Customer'),
        meta: {
            label: "Modifier un client"
        }
    }
]