import {DomainsApi} from "stalactite-data-client";
import ErrorHandler from "@/errors/handlers/ErrorHandler"

import axios from 'axios'

const API = new DomainsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        domainsNames: null
    },
    mutations: {
        'SET_DOMAINS': (state, domainsNames) => {
            state.domainsNames = domainsNames
        },
    },
    actions: {
        async fetchDomainsNames({commit}) {
            let resp;
            try {
                resp = await API.getDomainsNames()
            } catch (err) {
                commit('SET_DOMAINS', []);
                throw ErrorHandler.handle(err)
            }
            commit('SET_DOMAINS', resp.data)
        },
    }
}
