import posts from "./posts"
import leads from "./leads"
import me from "./me"

import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {UsersApi} from "stalactite-data-client"

import axios from 'axios'

const API = new UsersApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
        total: 0,
        pageSize: 0,
        user: null,
    },
    modules: {
        posts,
        leads,
        me
    },
    mutations: {
        'SET_USERS': (state, {_metas, results}) => {
            state.total = _metas.total
            state.pageSize = _metas.pageSize
            state.all = results
        },
        'SET_USER': (state, user) => {
            state.user = user
        },
        'ADD_USER': (state, user) => {
            state.all.push(user)
        },
        'UPDATE_USER': (state, user) => {
            const index = state.all.findIndex(item => item.uid === user.uid)
            if (index >= 0) {
                state.all[index] = user
            }
            state.user = user
        },
        'DELETE_USER': (state, user) => {
            state.all = state.all.filter(item => item.uid !== user.uid)
        },
    },
    actions: {
        async fetchUsers({commit}, params) {
            let resp;
            try {
                resp = await API.getUsers(params.page, params.search ?? undefined)
            } catch (err) {
                commit('SET_USERS', [])
                throw ErrorHandler.handle(err)
            }
            commit('SET_USERS', resp.data)
        },
        async fetchUser({commit}, uid) {
            let resp;
            try {
                resp = await API.getUser(uid)
            } catch (err) {
                commit('SET_USER', null)
                throw ErrorHandler.handle(err)
            }
            commit('SET_USER', resp.data)

            await this.dispatch("users/posts/fetchPosts", {uid}) // get the user's posts
            await this.dispatch("users/leads/fetchLeads", {uid}) // get the user's leads
        },
        async addUser({commit}, user) {

            const data = {
                firstName: user.firstName.trim(),
                lastName: user.lastName.trim(),
                email: user.email.trim(),
                admin: user.admin,
                posts: user.posts.map(post => post.uid),
                leads: user.leads.map(lead => lead.uid)
            }

            let resp;
            try {
                resp = await API.createUser(data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('ADD_USER', resp.data)
        },
        async updateUser({commit}, user) {
            const data = {
                firstName: user.firstName.trim(),
                lastName: user.lastName.trim(),
                email: user.email.trim(),
                admin: user.admin
            }

            try {
                await API.updateUser(user.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('UPDATE_USER', user)
        },
        async deleteUser({commit}, uid) {
            try {
                await API.deleteUser(uid)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_USER', {uid})
        }
    }
}
