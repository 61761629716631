<template>
  <nav>
    <b-sidebar id="app-sidebar" backdrop>
      <template #title>
        <AppTitle size="100%"/>
      </template>
      <NavList/>
    </b-sidebar>
  </nav>
</template>

<script>
import AppTitle from "./AppTitle"
import NavList from "./NavList";

export default {
  components: {
    AppTitle,
    NavList,
  }
}
</script>
