import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {UsersPostsApi} from 'stalactite-data-client'

import axios from 'axios'

const API = new UsersPostsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: []
    },
    mutations: {
        "SET_POSTS": (state, posts) => {
            state.all = posts
        },
        'ADD_POSTS': (state, posts) => {
            state.all = [...state.all, ...posts]
        },
        'REMOVE_POSTS': (state, posts) => {
            state.all = state.all.filter(userPost => !posts.some(post => post.uid === userPost.uid))
        }
    },
    actions: {
        async fetchPosts({commit}, {uid}) {
            let resp;
            try {
                resp = await API.getUserPosts(uid)
            } catch (err) {
                commit('SET_POSTS', []);
                throw ErrorHandler.handle(err)
            }
            commit('SET_POSTS', resp.data)
        },
        async addPosts({commit}, {user, posts}) {
            if (posts.length <= 0) {
                return;
            }

            const data = {posts: posts.map(post => post.uid)}

            try {
                await API.addUserPosts(user.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('ADD_POSTS', posts)
        },
        async removePosts({commit}, {user, posts}) {
            if (posts.length <= 0) {
                return;
            }

            const data = {posts: posts.map(post => post.uid)}

            try {
                await API.removeUserPosts(user.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('REMOVE_POSTS', posts)
        }
    }
}