import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {ServerAppsApi} from 'stalactite-auth-client'
import axios from 'axios'

const API = new ServerAppsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: [],
        total: 0,
        pageSize: 0,
        serverApp: null
    },
    mutations: {
        'SET_SERVER_APPS': (state, {_metas, results}) => {
            state.total = _metas.total
            state.pageSize = _metas.pageSize
            state.all = results
        },
        'SET_SERVER_APP': (state, serverApp) => {
            state.serverApp = serverApp
        },
        'ADD_SERVER_APP': (state, serverApp) => {
            state.all.push(serverApp)
        },
        'UPDATE_SERVER_APP': (state, serverApp) => {
            const index = state.all.findIndex(item => item.uid === serverApp.uid)
            if (index >= 0) {
                state.all[index] = serverApp
            }
            state.serverApp = serverApp
        },
        'DELETE_SERVER_APP': (state, serverApp) => {
            state.all = state.all.filter(item => item.uid !== serverApp.uid)
        }
    },
    actions: {
        async fetchServerApps({commit}, params) {
            let resp;
            try {
                resp = await API.getServerApps(params.page, params.name ?? undefined)
            } catch (err) {
                commit('SET_SERVER_APPS', [])
                throw ErrorHandler.handle(err)
            }
            commit('SET_SERVER_APPS', resp.data)
        },
        async fetchServerApp({commit}, uid) {
            let resp;
            try {
                resp = await API.getServerApp(uid)
            } catch (err) {
                commit('SET_SERVER_APP', null)
                throw ErrorHandler.handle(err)
            }
            commit('SET_SERVER_APP', resp.data)
        },
        async addServerApp({commit}, sa) {
            let resp;
            try {
                resp = await API.createServerApp({
                    name: sa.name.trim()
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('ADD_SERVER_APP', resp.data)
        },
        async updateServerApp({commit}, sa) {
            try {
                await API.updateServerApp(sa.uid, {
                    name: sa.name.trim()
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('UPDATE_SERVER_APP', sa)
        },
        async resetServerAppTokenSignatureKey({commit}, sa) {
            let resp;
            try {
                resp = await API.resetServerAppTokenSignatureKey(sa.uid, {
                    tokenSignatureKey: sa.tokenSignatureKey
                })
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('UPDATE_SERVER_APP', resp.data)
        },
        async deleteServerApp({commit}, uid) {
            try {
                await API.deleteServerApp(uid)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_SERVER_APP', {uid: uid})
        }
    }
}