export default [
    {

        path: '/ticket',
        name: 'ticket',
        component: () => import(/* webpackChunkName: "AddTicket" */ '@/views/tickets/AddTicket'),
        meta: {
            label: "Demande d'attribution"
        }
    },
    {
        path: '/tickets',
        name: 'tickets',
        component: () => import('@/views/tickets/GetTickets'),
        meta: {
            label: "Tickets"
        }
    }
]

