import Vue from 'vue'
import store from '@/store/index'
import axios from 'axios'

import ErrorHandler from '@/errors/handlers/ErrorHandler'
import {TokensApiFactory} from 'stalactite-auth-client'

export default async function (to, _, next) {
    // user logged in ?
    if (!store.getters['auth/isLoggedIn']) {
        if (to.matched.some(record => record.meta.anonymous)) {
            next()
        } else {
            await Vue.swal({
                icon: 'warning',
                title: 'Vous devez vous identifier'
            })
            next({name: 'home', query: {redirect: to.fullPath}})
        }
        return
    }

    const userToken = store.state.auth.userToken

    const API = new TokensApiFactory({apiKey: userToken});
    try {
        await API.checkToken()
    } catch (e) {
        await store.dispatch('auth/logout')
        await Vue.swal({
            icon: 'warning',
            title: 'Identifiant invalide'
        })
        next({name: 'home', query: {redirect: to.fullPath}})
        return
    }

    axios.defaults.headers.common['X-API-TOKEN'] = userToken

    // load user
    try {
        await store.dispatch('auth/loadUser')
    } catch (e) {
        await Vue.swal({
            icon: 'error',
            title: ErrorHandler.handle(e).message
        })
        await store.dispatch('auth/logout')
        next({name: 'home', query: {redirect: to.fullPath}})
        return
    }

    next()
}