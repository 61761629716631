import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {UsersMeApi} from 'stalactite-data-client'

import axios from 'axios'

const API = new UsersMeApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        me: null
    },
    mutations: {
        'SET_ME': (state, me) => {
            state.me = me
        }
    },
    actions: {
        async fetchMe({commit}) {
            let resp;
            try {
                resp = await API.getMeAsUser()
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('SET_ME', resp.data)
        },
    }
}