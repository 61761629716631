import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {AuthProvidersApi, ClientAppAuthProvidersApi} from "stalactite-auth-client";
import axios from 'axios'

const CLIENT_APP_AUTH_PROVIDER_API = new ClientAppAuthProvidersApi(undefined, undefined, axios);
const AUTH_PROVIDERS_API = new AuthProvidersApi(undefined, undefined, axios)

export default {
    namespaced: true,
    state: {
        all: [],
    },
    mutations: {
        'SET_PROVIDERS': (state, providers) => state.all = providers,
        'ADD_PROVIDER': (state, {type, provider}) => state.all[type].push(provider),
        'DELETE_PROVIDER': (state, {type, uid}) => state.all[type] = state.all[type].filter((p) => p.uid !== uid),
    },
    actions: {
        async fetchProviders({commit}, {uid}) {
            let resp;
            try {
                resp = await CLIENT_APP_AUTH_PROVIDER_API.getAllClientAppAuthProviders(uid);
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('SET_PROVIDERS', resp.data)
        },
        async addProvider({commit}, {type, clientApp, provider}) {
            let resp;
            try {
                switch (type) {
                    case 'google':
                        resp = await CLIENT_APP_AUTH_PROVIDER_API.createGoogleAuthProvider(clientApp.uid, provider)
                        break
                    case 'firebase':
                        resp = await CLIENT_APP_AUTH_PROVIDER_API.createFirebaseAuthProvider(clientApp.uid, provider)
                        break
                    default:
                        throw new Error('Invalid provider type')
                }
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('ADD_PROVIDER', {type, provider: resp.data})
        },
        async deleteProvider({commit}, {type, uid}) {
            try {
                switch (type) {
                    case 'google':
                        await AUTH_PROVIDERS_API.deleteGoogleAuthProvider(uid)
                        break
                    case 'firebase':
                        await AUTH_PROVIDERS_API.deleteFirebaseAuthProvider(uid)
                        break
                    default:
                        throw new Error('Invalid provider type')
                }
            } catch (err) {
                throw ErrorHandler.handle(err)
            }
            commit('DELETE_PROVIDER', {type, uid})
        }
    }
}
