import ErrorHandler from "@/errors/handlers/ErrorHandler"
import {DomainsRelationsApi, RelationsApi} from "stalactite-data-client"
import axios from 'axios'

const DOMAIN_RELATION_API = new DomainsRelationsApi(undefined, undefined, axios);
const RELATION_API = new RelationsApi(undefined, undefined, axios);

export default {
    namespaced: true,
    state: {
        all: []
    },
    mutations: {
        "SET_RELATIONS": (state, relations) => {
            state.all = relations
        },
        'ADD_USER_RELATIONS': (state, relations) => {
            state.all.users = [...state.all.users, ...relations]
        },
        'ADD_CUSTOMER_RELATIONS': (state, relations) => {
            state.all.customers = [...state.all.customers, ...relations]
        },
        'REMOVE_USER_RELATIONS': (state, relations) => {
            state.all.users = state.all.users.filter(userRelation => !relations.some(relation => userRelation.uid === relation.uid))
        },
        'REMOVE_CUSTOMER_RELATIONS': (state, relations) => {
            state.all.customers = state.all.customers.filter(customerRelation => !relations.some(relation => customerRelation.uid === relation.uid))
        }
    },
    actions: {
        async fetchRelations({commit}, domain) {
            let resp;
            try {
                resp = await DOMAIN_RELATION_API.getDomainRelations(domain.uid)
            } catch (err) {
                commit('SET_RELATIONS', []);
                throw ErrorHandler.handle(err)
            }
            commit('SET_RELATIONS', resp.data)
        },
        async addUserRelations({commit}, {domain, users}) {
            if (users.length <= 0) {
                return;
            }

            const data = {users: users.map(user => user.uid)}

            let resp;
            try {
                resp = await DOMAIN_RELATION_API.addUserRelations(domain.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('ADD_USER_RELATIONS', resp.data)
        },
        async addCustomerRelations({commit}, {domain, customers}) {
            if (customers.length <= 0) {
                return;
            }

            const data = {customers: customers.map(customer => customer.uid)}

            let resp;
            try {
                resp = await DOMAIN_RELATION_API.addCustomerRelations(domain.uid, data)
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('ADD_CUSTOMER_RELATIONS', resp.data)
        },
        async removeUserRelations({commit}, userRelations) {
            if (userRelations.length <= 0) {
                return;
            }

            try {
                await Promise.all(userRelations.map(relation => RELATION_API.deleteDomainUserRelation(relation.uid)))
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('REMOVE_USER_RELATIONS', userRelations)
        },
        async removeCustomerRelations({commit}, customerRelations) {
            if (customerRelations.length <= 0) {
                return;
            }

            try {
                await Promise.all(customerRelations.map(relation => RELATION_API.deleteDomainCustomerRelation(relation.uid)))
            } catch (err) {
                throw ErrorHandler.handle(err)
            }

            commit('REMOVE_CUSTOMER_RELATIONS', customerRelations)
        },
    }
}