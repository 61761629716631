<template>
  <b-row>
    <b-col class="pl-4">
      <ul class="fa-ul m-0">
        <li class="mt-3 mb-4"
            v-for="(route, key) in routes"
            :key="key"
        >
          <b-link
              :to="{name: route.name}"
              :disabled="$route.name === route.name"
              v-if="!route.needAdmin || (route.needAdmin && user.admin)"
          >
            <font-awesome-icon :icon="route.icon"/>
            <span>{{ route.label }}</span>
          </b-link>
        </li>
      </ul>
    </b-col>
  </b-row>
</template>

<script>
import {mapState} from "vuex";

export default {
  data() {
    return {
      routes: [
        {name: "domains", label: "Domaines", icon: 'building', needAdmin: true},
        {name: "users", label: "Utilisateurs", icon: 'user', needAdmin: true},
        {name: "customers", label: "Clients", icon: 'user-tag', needAdmin: true},
        {name: "posts", label: "Postes", icon: 'tags', needAdmin: true},
        {name: "permissions", label: "Permissions", icon: 'unlock', needAdmin: true},
        {name: "clientApps", label: "Applications clientes", icon: 'mobile', needAdmin: true},
        {name: "serverApps", label: "Applications serveurs", icon: 'server', needAdmin: true},
        {name: "tickets", label: "Tickets", icon: 'bell', needAdmin: true},
        {name: "ticket", label: "Demande d'attribution", icon: 'archive'},
      ]
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.me,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.svg-inline--fa {
  min-width: 10%;
  color: $jalis-blue;
}

a {
  color: $jalis-grey;
  text-decoration: none !important;

  &:hover {
    color: darken($jalis-grey, 30%);
  }

  &.disabled {
    color: $jalis-blue;
    cursor: default;
  }
}
</style>
