export default [
    {
        path: '/posts',
        name: 'posts',
        component: () => import(/* webpackChunkName: "Posts" */ '@/views/posts/Posts'),
        meta: {
            label: "Postes"
        }
    },
    {
        path: '/posts/add',
        name: 'addPost',
        component: () => import(/* webpackChunkName: "AddPost" */ '@/views/posts/AddPost'),
        meta: {
            label: "Ajouter un poste"
        }
    },
    {
        path: '/posts/:uid',
        name: 'post',
        props: true,
        component: () => import(/* webpackChunkName: "Post" */ '@/views/posts/Post'),
        meta: {
            label: "Modifier un poste"
        }
    }
]