import jwt from 'jsonwebtoken'

import ErrorHandler from '@/errors/handlers/ErrorHandler'
import AuthenticationError from '@/errors/AuthenticationError'
import StalactiteError from '@/errors/StalactiteError'

import {TokensApi} from 'stalactite-auth-client'
import {CustomersMeApi, UsersMeApi} from 'stalactite-data-client'

const USER_TOKEN_STORAGE_KEY = 'user-token'

export const USER_TYPE = 'user';
export const CUSTOMER_TYPE = 'customer';

function logout(state) {
    state.userToken = null
    state.me = null
    state.userType = null
}

export default {
    namespaced: true,
    state: {
        userToken: localStorage.getItem(USER_TOKEN_STORAGE_KEY),
        userType: null,
        me: null
    },
    getters: {
        isLoggedIn: state => !!state.userToken,
        isUser: (state, getters) => getters.isLoggedIn && state.userType === USER_TYPE,
        isCustomer: (state, getters) => getters.isLoggedIn && state.userType === CUSTOMER_TYPE,
    },
    mutations: {
        'LOGOUT': (state) => logout(state),
        'AUTH_ERROR': (state) => logout(state),
        'AUTH_SUCCESS': (state, token) => {
            state.userToken = token
        },
        'SET_CUSTOMER': (state, user) => {
            if (user === null || typeof user !== 'object') {
                throw new AuthenticationError('Utilisateur invalide')
            }
            state.userType = CUSTOMER_TYPE
            state.me = user
        },
        'SET_USER': (state, user) => {
            if (user === null || typeof user !== 'object') {
                throw new AuthenticationError('Utilisateur invalide')
            }
            state.userType = USER_TYPE
            state.me = user
        }
    },
    actions: {
        async login({commit}, token) {
            const data = {
                token: token,
                app: process.env.VUE_APP_STALACTITE_TRUSTED_APP_NAME
            }

            const API = new TokensApi();

            let resp;
            try {
                resp = await API.login(data)
            } catch (err) {
                throw ErrorHandler.handle(err);
            }

            if (!resp.data.token) {
                throw new StalactiteError("Réponse de l'API invalide");
            }

            commit('AUTH_SUCCESS', resp.data.token)
        },
        logout({commit}) {
            commit('LOGOUT')
        },
        async loadUser({commit, state}) {
            if (!state.userToken) {
                throw new AuthenticationError('Authentification requise')
            }
            const decodedJwt = jwt.decode(state.userToken)
            switch (decodedJwt.type) {
                case 'user': {
                    const API = new UsersMeApi({apiKey: state.userToken});
                    let resp;
                    try {
                        resp = await API.getMeAsUser()
                    } catch (e) {
                        throw new AuthenticationError('Une erreur est survenu lors de la récupération de l\'utilisateur')
                    }
                    commit('SET_USER', resp.data)
                    break
                }
                case 'customer': {
                    const API = new CustomersMeApi({apiKey: state.userToken});
                    let resp;
                    try {
                        resp = await API.getMeAsCustomer()
                    } catch (e) {
                        throw new AuthenticationError('Une erreur est survenu lors de la récupération de l\'utilisateur')
                    }
                    commit('SET_CUSTOMER', resp.data)
                    break
                }
                default: {
                    throw new AuthenticationError('Type d\'utilisateur invalide')
                }
            }
        }
    }
}

export const userTokenWatcher = [
    (state) => state.auth.userToken,
    (newValue) => {
        newValue !== null ? localStorage.setItem(USER_TOKEN_STORAGE_KEY, newValue) : localStorage.removeItem(USER_TOKEN_STORAGE_KEY)
    }
]
