export default [
    {
        path: '/users',
        name: 'users',
        component: () => import(/* webpackChunkName: "Users" */ '@/views/users/Users'),
        meta: {
            label: "Utilisateurs"
        }
    },
    {
        path: '/users/add',
        name: 'addUser',
        component: () => import(/* webpackChunkName: "AddUser" */ '@/views/users/AddUser'),
        meta: {
            label: "Ajouter un utilisateur"
        }
    },
    {
        path: '/users/:uid',
        name: 'user',
        props: true,
        component: () => import(/* webpackChunkName: "User" */ '@/views/users/User'),
        meta: {
            label: "Modifier un utilisateur"
        }
    }
]