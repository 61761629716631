import StalactiteErrors from '../stalactite.json'

export default class StalactiteErrorHandler {
    static handle(request, {data: {type, code}}) {
        if (type === undefined || code === undefined) {
            return null
        }

        const api = this.getApiFromRequest(request)
        const message = getObjectPropertyFromPath(StalactiteErrors[api]?.[type], code)
        return message !== undefined ? new Error(message) : null
    }

    static getApiFromRequest(request) {
        if (request.responseURL?.startsWith(process.env.VUE_APP_STALACTITE_API_HOST + '/auth')) {
            return 'auth'
        }

        if (request.responseURL?.startsWith(process.env.VUE_APP_STALACTITE_API_HOST + '/data')) {
            return 'data'
        }

        return ''
    }
}

function getObjectPropertyFromPath(object, path) {
    path = path.split('.');

    let index = 0;
    let length = path.length;

    while (object != null && index < length) {
        object = object[path[index++]];
    }
    return (index && index === length) ? object : undefined;
}