export default [
    {
        path: '/permissions',
        name: 'permissions',
        component: () => import(/* webpackChunkName: "Permissions" */ '@/views/permissions/Permissions'),
        meta: {
            label: "Permissions"
        }
    },
    {
        path: '/permissions/add',
        name: 'addPermission',
        component: () => import(/* webpackChunkName: "AddPermission" */ '@/views/permissions/AddPermission'),
        meta: {
            label: "Ajouter une permission"
        }
    },
    {
        path: '/permissions/:uid',
        name: 'permission',
        props: true,
        component: () => import(/* webpackChunkName: "Permission" */ '@/views/permissions/Permission'),
        meta: {
            label: "Modifier une permission"
        }
    }
]